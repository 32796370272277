import { Typography } from '@visma-netvisor/react-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const StepLabel = ({ children }: PropsWithChildren) => (
  <StyledTypography color="info" variant="headingH4Bold">
    {children}
  </StyledTypography>
);

const StyledTypography = styled(Typography)`
  margin: 10px 0;
`;
