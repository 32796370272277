import React, {
  useContext,
  createContext,
  useState,
  PropsWithChildren,
} from 'react';
import { useModal } from './ModalContext';
import { useQueryClient } from 'react-query';

type MaintenanceType = {
  isMaintenance: boolean;
  endsAt: Date;
};

type MaintenanceContextType = {
  maintenance: MaintenanceType;
  setMaintenance: (value: MaintenanceType) => void;
};

const MaintenanceContext = createContext<MaintenanceContextType>(null!);

export const MaintenanceProvider = ({ children }: PropsWithChildren) => {
  const [maintenance, setMaintenance] = useState<MaintenanceType>({
    isMaintenance: false,
    endsAt: new Date(),
  });

  const value = {
    maintenance,
    setMaintenance,
  };

  return (
    <MaintenanceContext.Provider value={value}>
      {children}
    </MaintenanceContext.Provider>
  );
};

export const useMaintenance = () => {
  return useContext(MaintenanceContext);
};

export function useCheckMaintenance() {
  const { setModal } = useModal();
  const { setMaintenance } = useMaintenance();
  const queryClient = useQueryClient();

  return (res: Response) => {
    if (res.status === 503) {
      const endsAt = parseInt(res.headers.get('Retry-After') ?? '');
      if (endsAt) {
        setModal('Maintenance');
        setMaintenance({ isMaintenance: true, endsAt: new Date(endsAt) });

        // Fixes a flaky test
        queryClient.cancelQueries();
      }
    }
  };
}
