import React, { useState } from 'react';
import {
  Layout,
  Typography,
  Link,
  Button,
} from '@visma-netvisor/react-component-library';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { Stepper, StepLabel, Step } from '../Stepper';
import { SETTINGS } from '../../constants';
import {
  generatingCodes,
  resetCodeCounter,
  resetGeneratingCodes,
  setLoginMethod,
} from '../../api/queries/useStartLoginRequest';
import { togglePoller } from 'src/api/queries/useGetLoginRequestStatus';
import { LoginMethod } from 'src/api/types/loginMethod';
import { useMaintenance } from '../../api/app/MaintenanceContext';
import { useQueryClient } from 'react-query';
import useLogin from '../../api/app/Login';
import Identifier from '../Identifier';

type LoginType = 'qrcode' | 'identifier';

function QRLogin() {
  useLogin();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [loginType, setLoginType] = useState<LoginType>('qrcode');

  const handleLoginTypeChange = (loginType: LoginType) => {
    resetCodeCounter();
    resetGeneratingCodes();
    setLoginMethod(
      loginType === 'qrcode' ? LoginMethod.QrCode : LoginMethod.Identifier
    );
    queryClient.refetchQueries('start_login_request');
    setLoginType(loginType);
  };

  const { maintenance } = useMaintenance();

  if (maintenance.isMaintenance) {
    return null;
  }

  return (
    <>
      {generatingCodes ? (
        <Layout.Grid styleOverrides={{ width: '100%' }}>
          <Layout.Item
            columns="span 12"
            columnsSm="span 7"
            styleOverrides={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
            }}
          >
            <Stepper>
              <Step index={0}>
                <StepLabel>
                  <Typography
                    variant="contentTextRegular"
                    styleOverrides={{ color: '#003254' }}
                  >
                    <Trans
                      i18nKey="QRLogin.First"
                      components={[
                        <Link
                          href={SETTINGS.NETVISOR_MOBILE_URL}
                          target="_blank"
                          style={{ color: '#007ACA' }}
                        ></Link>,
                      ]}
                    />
                  </Typography>
                </StepLabel>
              </Step>
              <Step index={1}>
                <StepLabel>
                  <Typography
                    variant="contentTextRegular"
                    styleOverrides={{ color: '#003254' }}
                  >
                    <Trans
                      i18nKey="QRLogin.Second"
                      components={[
                        loginType === 'identifier' ? (
                          <StyledButton
                            variant="link"
                            onClick={() => handleLoginTypeChange('qrcode')}
                          ></StyledButton>
                        ) : (
                          <></>
                        ),
                        loginType === 'qrcode' ? (
                          <StyledButton
                            variant="link"
                            onClick={() => handleLoginTypeChange('identifier')}
                          ></StyledButton>
                        ) : (
                          <></>
                        ),
                      ]}
                    />
                  </Typography>
                </StepLabel>
              </Step>
              <Step index={2}>
                <StepLabel>
                  <Typography
                    variant="contentTextRegular"
                    styleOverrides={{ color: '#003254' }}
                  >
                    {t('QRLogin.Third')}
                  </Typography>
                </StepLabel>
              </Step>
            </Stepper>
          </Layout.Item>
          <Identifier loginType={loginType} />
        </Layout.Grid>
      ) : (
        <StyledResetContainer>
          <StyledResetItemContainer>
            <StyledResetText as="h3" variant="headingH3Bold">
              {t('QRLogin.ResetTitle')}
            </StyledResetText>
          </StyledResetItemContainer>
          <StyledResetItemContainer>
            <StyledResetButton
              onClick={() => {
                resetCodeCounter();
                resetGeneratingCodes();
                togglePoller();
                queryClient
                  .getQueryCache()
                  .find('start_login_request')
                  ?.reset();
              }}
            >
              {t('QRLogin.ResetButton')}
            </StyledResetButton>
          </StyledResetItemContainer>
        </StyledResetContainer>
      )}
    </>
  );
}

export default QRLogin;

const StyledButton = styled(Button)`
  padding: 0;
  gap: 0;
  min-width: auto;
  height: auto;
  color: #007aca !important;
`;

const StyledResetContainer = styled.li`
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
`;

const StyledResetItemContainer = styled.div`
  display: flex;
  justify-content: center;

  padding-top: 10px;
  padding-bottom: 10px;
`;

const StyledResetText = styled(Typography)`
  align-text: center;
  padding-top: 10px;
  font-size: 15px;
`;

const StyledResetButton = styled.button`
  cursor: pointer;
  background: #f4f8fb none repeat scroll 0 0;
  border: 1px solid #d6d4d4;
  border-radius: 3px;
  color: #3a4f56;
  float: left;
  font-weight: 400;
  font-size: 1rem;
  line-height: 2rem;
  margin: 0 5px 0 0;
  padding: 0 10px;
  text-decoration: none;
  transition: all 0.3s ease 0s;
`;
