import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import setupI18n from './i18';
import { createGlobalStyle } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ModalProvider } from './api/app/ModalContext';
import { NoteProvider } from './api/app/NoteContext';
import { MaintenanceProvider } from './api/app/MaintenanceContext';

const root = document.getElementById('root') as HTMLElement;

const languages = ['fi', 'en', 'sv'];
const pathLanguage = languages.find((item) =>
  location.pathname.includes(`/${item}`)
);

const storedLanguage = localStorage.getItem('lang');

let lang;
if (pathLanguage) {
  lang = pathLanguage;
} else if (storedLanguage) {
  lang = storedLanguage;
} else if (
  navigator.language.includes('en') ||
  navigator.language.includes('sv')
) {
  lang = navigator.language.split('-')[0];
} else {
  lang = 'fi';
}

setupI18n(lang);
if (lang !== pathLanguage) {
  const url = new URL(location.href);
  url.pathname = `/${lang}`;
  window.history.replaceState(null, '', url);
}

// Create a client
const queryClient = new QueryClient();

const GlobalStyle = createGlobalStyle`
#root {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  min-height: 100vh;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}
body {
  margin: 0;
  height: 100%;
}
`;

createRoot(root).render(
  <React.StrictMode>
    <GlobalStyle />
    <QueryClientProvider client={queryClient}>
      <MaintenanceProvider>
        <ModalProvider>
          <NoteProvider>
            <App />
          </NoteProvider>
        </ModalProvider>
      </MaintenanceProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
