import { useEffect } from 'react';
import { useNote } from './NoteContext';
import useGetCommunityMessages from '../queries/useGetCommunityMessages';

const useCommunityMessages = () => {
  const { addMessages, addError } = useNote();
  const { data, status, error: fetchError } = useGetCommunityMessages();

  useEffect(() => {
    if (status !== 'success') {
      return;
    }

    addMessages(data.map((v) => ({ ...v, type: 'warning' })));
  }, [status, data, addMessages]);

  useEffect(() => {
    if (fetchError) {
      addError('Failed to fetch community messages', 'Message fetch error');
    }
  }, [fetchError, addError]);
};

export default useCommunityMessages;
