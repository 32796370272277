import React from 'react';
import {
  Layout,
  Typography,
  breakpoint,
} from '@visma-netvisor/react-component-library';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { QRCodeSVG } from 'qrcode.react';
import ProgressBar from '../ProgressBar';
import { useTranslation } from 'react-i18next';

const Identifier = ({ loginType }: { loginType: string }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const queryState = queryClient.getQueryState(['start_login_request']);
  const queryData: any = queryClient.getQueryData(['start_login_request']);

  return (
    <QRCodeLayoutItem columns="span 12" columnsSm="span 5">
      <Layout.Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flexGap={16}
        styleOverrides={{ maxWidth: '240px' }}
      >
        <Typography
          variant="contentTextRegular"
          styleOverrides={{ textTransform: 'uppercase' }}
        >
          {loginType === 'qrcode'
            ? t('QRLogin.QRCode')
            : t('QRLogin.Identifier')}
        </Typography>
        {loginType === 'qrcode' && (
          <QRCodeSVG
            value={`netvisor-app:login?identifier=${queryData?.Root?.LoginRequest?.LoginIdentifier}`}
            size={240}
            level="H"
          />
        )}
        {loginType === 'identifier' && (
          <Typography
            variant="contentHeadingH1Bold"
            styleOverrides={{ letterSpacing: '0.1em' }}
          >
            {!queryState?.isFetching &&
              queryData?.Root?.LoginRequest?.LoginIdentifier}
          </Typography>
        )}
        <ProgressBar count={queryState?.dataUpdateCount ?? 0} />
      </Layout.Flex>
    </QRCodeLayoutItem>
  );
};

export default Identifier;

const QRCodeLayoutItem = styled(Layout.Item)`
  display: flex;
  justify-content: center;
  border-top: 1px solid #c5d7e1;
  padding: 8px;
  @media (min-width: ${breakpoint.sm}) {
    border-top: none;
    border-left: 1px solid #c5d7e1;
  }
`;
