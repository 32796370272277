import {
  colorPalette,
  font,
  Layout,
  Typography,
} from '@visma-netvisor/react-component-library';
import * as React from 'react';
import styled from 'styled-components';
import advert from '../../assets/advert/advert.json';

function Advertisement() {
  let headerTitle = advert.headerTitle;
  let mainTitle = advert.mainTitle;
  let description = advert.description;
  let descriptionBullets = advert.descriptionBullets;
  let button = advert.button;
  let background = advert.background;
  let backgroundColor = advert.backgroundColor;
  let link = advert.link;

  return (
    <Container hex={backgroundColor}>
      <Layout.Flex
        flexDirection="column"
        justifyContent="flex-start"
        flexGap={0}
      >
        <Header>
          <HeaderTitle as="h3" color="primary" variant="contentHeadingH3Bold">
            {headerTitle}
          </HeaderTitle>
        </Header>
        <MainTitle
          as="h2"
          color="whiteInverse"
          variant="contentHeadingH2Regular"
        >
          {mainTitle}
        </MainTitle>
        <Paragraph as="p" color="whiteInverse" variant="contentTextRegular">
          {description}
        </Paragraph>
        {descriptionBullets.length ? (
          <BulletList>
            {descriptionBullets.map((bullet, i) => (
              <BulletListItem key={i}>{bullet}</BulletListItem>
            ))}
          </BulletList>
        ) : null}
        <ReadMore href={link} target="_blank">
          {button}
        </ReadMore>
        <Image base64={background} />
      </Layout.Flex>
    </Container>
  );
}

export default Advertisement;

const Container = styled.article<{ hex: string }>`
  box-sizing: border-box;
  background: ${(props) => (props.hex.includes('#') ? props.hex : '#007aca')};
  text-align: center;
  padding: 30px 40px;
  height: 100%;
`;

const Header = styled.header`
  box-sizing: border-box;
  height: 80px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const HeaderTitle = styled(Typography)`
  box-sizing: border-box;
  display: block;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 2rem;
  max-width: 45rem;
`;

const MainTitle = styled(Typography)`
  box-sizing: border-box;
  display: block;
  font-size: 24px;
  line-height: 1.16666;
  font-weight: 500;
  margin: 0 auto 4rem auto;
  max-width: 45rem;
`;

const Paragraph = styled(Typography)`
  box-sizing: border-box;
  margin: 0 auto 2rem auto;
  padding: 0;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.05rem;
  max-width: 45rem;
`;

const BulletList = styled.ul`
  color: ${colorPalette.font.whiteInverse};
  font: ${font.contentTextRegular}
  box-sizing: border-box;
  margin: 0 auto 2rem auto;
  padding: 0;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.05rem;
  padding: 0 0 0 1em;
  max-width: 35rem;
`;

const BulletListItem = styled.li`
  text-align: left;
`;

const ReadMore = styled.a`
  box-sizing: border-box;
  margin: 0 auto 2rem auto;
  padding: 0;
  display: inline-block;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #ffffff;
  border-radius: 1000px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  padding: 1rem 5rem;
  margin: 1rem 2rem;

  :hover {
    background-color: #3395d5;
  }
`;

const Image = styled.img<{ base64: string }>`
  box-sizing: border-box;
  display: block;
  margin: 1rem auto;
  padding: 0;
  width: 100%;
  max-width: 500px;
  height: auto;

  content: url(${(props) =>
    props.base64.includes('base64') ? props.base64 : ''});
`;
