import React, { useState, useEffect } from 'react';
import { ProgressBar as CompLibProgressBar } from '@visma-netvisor/react-component-library';
import styled from 'styled-components';
import { SETTINGS } from '../../constants';

const progressUpdateIntervalMs = 300;

const ProgressBar = ({ count }: { count: number }) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    setProgress(100);

    const statusPoller = setInterval(() => {
      setProgress((value) => {
        if (
          value -
            100 / (SETTINGS.CODE_VALID_TIME_MS / progressUpdateIntervalMs) >
          0
        ) {
          return (
            value -
            100 / (SETTINGS.CODE_VALID_TIME_MS / progressUpdateIntervalMs)
          );
        } else {
          clearInterval(statusPoller);
          return 0;
        }
      });
    }, progressUpdateIntervalMs);
    // clean up the poller on unmount
    return () => clearInterval(statusPoller);
  }, [count]);

  return <StyledProgressBar progress={progress} variant="neutral" />;
};

export default ProgressBar;

const StyledProgressBar = styled(CompLibProgressBar)`
  width: 100%;
  > div {
    height: 4px;
  }
`;
