import {
  measurement,
  Typography,
} from '@visma-netvisor/react-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface StepProps extends PropsWithChildren {
  index?: number;
}

export const Step = ({ children, index = 0, ...rest }: StepProps) => {
  return (
    <StepButtonWrapper>
      <StepButton {...rest}>
        <Typography color="whiteInverse" variant="headingH4Bold">
          {index + 1}
        </Typography>
      </StepButton>
      {children}
    </StepButtonWrapper>
  );
};

export const StepButtonWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const StepButton = styled.span`
  box-sizing: border-box;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: ${measurement.stepperButton};
  width: ${measurement.stepperButton};
  background-color: #0095f8;
  border: 0;
  border-radius: 50%;
  & svg {
    font-size: 24px;
  }
`;
