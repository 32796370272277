import * as React from 'react';
import { font, colorPrimary } from '@visma-netvisor/react-component-library';
import styled from 'styled-components';
import { useMaintenance } from '../../api/app/MaintenanceContext';

export interface LoginOptionButtonProps {
  size?: 'large' | 'normal';
  /** Button content */
  children: React.ReactNode;
  bankId: number;
  isForeign?: boolean;
  handleClick: (bankId: number, isForeign: boolean | undefined) => void;
}

const LoginOptionButton = React.forwardRef(
  (
    {
      size = 'normal',
      children,
      bankId,
      isForeign,
      handleClick,
      ...rest
    }: LoginOptionButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const { maintenance } = useMaintenance();
    const { isMaintenance } = maintenance;

    return (
      <StyledButton
        size={size}
        ref={ref}
        {...rest}
        onClick={() => handleClick(bankId, isForeign)}
        disabled={isMaintenance}
      >
        {children}
      </StyledButton>
    );
  }
);

export default LoginOptionButton;

const StyledButton = styled.button<Partial<LoginOptionButtonProps>>`
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  padding: ${(props) => (props.size === 'large' ? '2rem' : '15px')};
  min-width: 32px;
  min-height: 32px;
  font: ${font.headingH4Regular};
  background-color: ${colorPrimary.backgroundStrong};
  color: ${colorPrimary.textStrong};
  border: none;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;

  // login styles
  background-color: transparent;
  width: 100%;

  &:hover,
  &:focus,
  &:focus-within {
    outline: none;
    // TODO: replace with shadow variables
    -webkit-box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.3);
  }

  &:disabled {
    cursor: default;
  }

  // Inner components
  & > img {
    height: 35px;
    max-width: 80%;
    width: auto;
    margin: 0 auto;
  }
`;
