import React from 'react';
import NotificationItem from './NotificationItem';
import { useNote } from '../../api/app/NoteContext';
import { useMaintenance } from '../../api/app/MaintenanceContext';
import useCommunityMessages from '../../api/app/Notifications';

const Notifications = () => {
  useCommunityMessages();
  const { maintenance } = useMaintenance();
  const { clearMessage, messages } = useNote();

  if (maintenance.isMaintenance) {
    return null;
  }

  return (
    <>
      {messages?.map((item) => (
        <NotificationItem
          id={item.id.toString()}
          key={item.id}
          title={item.title}
          variant={item.type || 'warning'}
          clearMessage={clearMessage}
        />
      ))}
    </>
  );
};

export default Notifications;
