import React, { PropsWithChildren } from 'react';
import {
  Modal as NVModal,
  ModalHeader,
  ModalContent,
  Typography,
} from '@visma-netvisor/react-component-library';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../api/app/ModalContext';
import { useMaintenance } from 'src/api/app/MaintenanceContext';

const Heading = ({ children }: PropsWithChildren) => (
  <Typography as="h3" variant="contentHeadingH3Bold">
    {children}
  </Typography>
);

const Text = ({ children }: PropsWithChildren) => (
  <Typography
    as="p"
    variant="contentTextRegular"
    styleOverrides={{ marginBottom: 18 }}
  >
    {children}
  </Typography>
);

const Maintenance = () => {
  const { maintenance } = useMaintenance();
  const { t, i18n } = useTranslation();
  return (
    <>
      <ModalHeader>{t('Maintenance.Header')}</ModalHeader>
      <ModalContent styleOverrides={{ padding: 24 }}>
        <Heading>{t('Maintenance.AccessRestricted')}</Heading>
        <br />
        <Heading>
          {t('Maintenance.EndsAt', {
            endsAt: maintenance.endsAt.toLocaleString(i18n.language),
          })}
        </Heading>
      </ModalContent>
    </>
  );
};

const LoginHelp = () => {
  const { t } = useTranslation();
  return (
    <>
      <ModalHeader>{t('LoginProblems.Header')}</ModalHeader>
      <ModalContent styleOverrides={{ padding: 24 }}>
        <Heading>{t('LoginProblems.UserRightsHeader')}</Heading>
        <Text>{t('LoginProblems.UserRightsText')}</Text>
        <Heading>{t('LoginProblems.NewUser')}</Heading>
        <Text>{t('LoginProblems.NewUserText')}</Text>
        <Heading>{t('LoginProblems.MobileLoginNotWorkingHeader')}</Heading>
        <Text>{t('LoginProblems.MobileLoginNotWorkingText')}</Text>
        <Heading>{t('LoginProblems.SupportHeader')}</Heading>
        <Text>{t('LoginProblems.SupportText')}</Text>
      </ModalContent>
    </>
  );
};

const Modal = () => {
  const { modal, setModal } = useModal();

  if (modal === null) {
    return null;
  }

  let toggleClose: undefined | (() => void) = () => setModal(null);

  let modalContent;
  switch (modal) {
    case 'Maintenance':
      modalContent = <Maintenance />;
      toggleClose = undefined;
      break;
    case 'LoginProblems':
    default:
      modalContent = <LoginHelp />;
  }

  return (
    <NVModal
      anchorTo="dialog"
      modalWidth={800}
      toggleClose={toggleClose}
      hideFooter={true}
    >
      {modalContent}
    </NVModal>
  );
};

export default Modal;
