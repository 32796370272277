import { Layout } from '@visma-netvisor/react-component-library';
import React from 'react';
import LoginOptionButton from '../LoginOptionButton';
import LoginOption from './LoginOption';
import DanskeBankLogo from '../../assets/images/danskebanklogo.svg';
import NordeaLogo from '../../assets/images/nordealogo.png';
import OPLogo from '../../assets/images/oplogo_TEMP.jpg';
import AktiaLogo from '../../assets/images/aktialogonew.png';
import SäästöpankkiLogo from '../../assets/images/Splogo.jpg';
import POPpankkiLogo from '../../assets/images/POPpankkilogo_TEMP.png';
import ÅlandsbankenLogo from '../../assets/images/alandsbankenlogo.png';
import OmaSPLogo from '../../assets/images/omasplogo.jpg';
import SPankkiLogo from '../../assets/images/spankkilogo.svg';
import HandelsbankenLogo from '../../assets/images/handelsbankenlogo.svg';

// TODO:
// Nordea and ålandsbanken Logos are converted into base64 with CRA.
// Convert to build-scripts to get rid of this.

export default function BankLoginOptions(props: {
  handleClick: (bankId: number, isForeign: boolean | undefined) => void;
}) {
  const banks = [
    {
      id: 10,
      name: 'Danskebank',
      imgUrl: DanskeBankLogo,
    },
    {
      id: 4,
      name: 'Nordea',
      imgUrl: NordeaLogo,
    },
    {
      id: 2,
      name: 'Osuuspankki',
      imgUrl: OPLogo,
    },
    {
      id: 8,
      name: 'Aktia',
      imgUrl: AktiaLogo,
    },
    {
      id: 1,
      name: 'Säästöpankki',
      imgUrl: SäästöpankkiLogo,
    },
    {
      id: 6,
      name: 'pop',
      imgUrl: POPpankkiLogo,
    },
    {
      id: 9,
      name: 'Ålandsbanken',
      imgUrl: ÅlandsbankenLogo,
    },
    {
      id: 5,
      name: 'Oma SP',
      imgUrl: OmaSPLogo,
    },
    {
      id: 7,
      name: 'S-pankki',
      imgUrl: SPankkiLogo,
    },
    {
      id: 3,
      name: 'Handelsbanken',
      imgUrl: HandelsbankenLogo,
    },
  ];

  return (
    <>
      {banks.map((bank) => (
        <Layout.Item columns="span 6" columnsMd="span 3" key={bank.id}>
          <LoginOption>
            <LoginOptionButton
              aria-label={bank.name}
              bankId={bank.id}
              handleClick={props.handleClick}
            >
              <img src={bank.imgUrl} alt={bank.name} />
            </LoginOptionButton>
          </LoginOption>
        </Layout.Item>
      ))}
    </>
  );
}
