import React, { PropsWithChildren } from 'react';
import { Layout, measurement } from '@visma-netvisor/react-component-library';
import styled, { CSSObject } from 'styled-components';

export interface LoginOptionProps extends PropsWithChildren {
  /** Inline styles */
  styleOverrides?: CSSObject;
}

const LoginOption = ({ children, styleOverrides }: LoginOptionProps) => {
  return (
    <BorderedLayout
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      styleOverrides={styleOverrides}
    >
      {children}
    </BorderedLayout>
  );
};

export default LoginOption;

const BorderedLayout = styled(Layout.Flex)`
  border: 1px solid #c7d8ef;
  border-radius: ${measurement.borderRadiusDefault};
`;
