import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

type ModalState = null | 'LoginProblems' | 'Maintenance';

type ModalContextType = {
  modal: ModalState;
  setModal: Dispatch<SetStateAction<ModalState>>;
};

const ModalContext = createContext<ModalContextType>(null!);

export const ModalProvider = ({ children }: PropsWithChildren) => {
  const [modal, setModal] = useState<ModalState>(null);

  return (
    <ModalContext.Provider value={{ modal, setModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
