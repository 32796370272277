import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fi from './translations/fi.json';
import en from './translations/en.json';
import se from './translations/se.json';

export default function setupI18n(lng = 'fi') {
  const resources = {
    fi: {
      translation: fi,
    },
    en: {
      translation: en,
    },
    sv: {
      translation: se,
    },
  };

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      preload: true,
      lng,
      fallbackLng: 'fi',
      supportedLngs: ['fi', 'en', 'sv'],
    });
  return i18n;
}
