import * as React from 'react';
import {
  Layout,
  breakpoint,
  Typography,
} from '@visma-netvisor/react-component-library';
import Advertisement from '../../../Components/Advertisement';
import LoginOption from '../../../Components/LoginOption';
import LoginOptionButton from '../../../Components/LoginOptionButton';
import QRLogin from '../../../Components/QRLogin';
import NetvisorLogo from '../../../assets/images/netvisor-logo-new.svg';
import MobileVerificationLogo from '../../../assets/images/mobiilivarmenne_2018.svg';
import EsteIdLogo from '../../../assets/images/esteidlogo.png';
import SwedishBankIdlogo from '../../../assets/images/swedishbankidlogo.png';
import NorwegianBankIdLogo from '../../../assets/images/norwegianbankidlogo.png';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import BankLoginOptions from 'src/Components/LoginOption/BankLoginOptions';

function Content() {
  const { t, i18n } = useTranslation();
  const languages = ['fi', 'en', 'sv'];

  const handleClick = (bankId: number, isForeign: boolean | undefined) => {
    const languageId = languages.indexOf(i18n.language) + 1;

    if (bankId && isForeign) {
      window.location.href = `https://suomi.netvisor.fi/evo/login/RedirectToSignicat.aspx?provider=${bankId}`;
      return;
    }

    if (bankId) {
      window.location.href = `https://suomi.netvisor.fi/evo/login/openid/authenticationhandler.aspx?id=${bankId}&languageid=${languageId}`;
    }
  };

  return (
    <StyledLayoutContainer gridGap={0}>
      <StyledLayoutItem columns="span 12" columnsLg="span 8">
        <Header>
          <img src={NetvisorLogo} style={{ maxWidth: '125px' }} />
        </Header>
        <Section>
          <Typography
            as="h1"
            variant="headingH1Semibold"
            styleOverrides={{
              fontSize: '36px',
              margin: '36px 0',
              fontWeight: '800',
              color: '#003254',
            }}
          >
            {t('MainContent.Header')}
          </Typography>

          <Typography
            as="h3"
            variant="contentHeadingH3Semibold"
            styleOverrides={{ marginBottom: '1em', color: '#003254' }}
          >
            {t('MainContent.LoginWithMobileApp')}
          </Typography>

          <LoginOption styleOverrides={{ padding: '15px 0' }}>
            <QRLogin />
          </LoginOption>

          <Typography
            as="h3"
            variant="contentHeadingH3Semibold"
            styleOverrides={{
              marginTop: '1.5em',
              marginBottom: '1em',
              color: '#003254',
            }}
          >
            {t('MainContent.LoginWithMobileVerification')}
          </Typography>

          <Layout.Grid>
            <Layout.Item
              columns="span 12"
              columnsSm="span 6"
              columnsMd="span 3"
            >
              <LoginOption>
                <LoginOptionButton
                  size="large"
                  aria-label="Login with Mobiilivarmenne"
                  bankId={11}
                  handleClick={handleClick}
                >
                  <img src={MobileVerificationLogo} alt="Mobiilivarmenne" />
                </LoginOptionButton>
              </LoginOption>
            </Layout.Item>
          </Layout.Grid>

          <Typography
            as="h3"
            variant="contentHeadingH3Semibold"
            styleOverrides={{
              marginTop: '1.5em',
              marginBottom: '1em',
              color: '#003254',
            }}
          >
            {t('MainContent.LoginWithBankCredentials')}
          </Typography>

          <Layout.Grid styleOverrides={{ marginBottom: '32px' }}>
            <BankLoginOptions handleClick={handleClick} />
          </Layout.Grid>

          <Layout.Grid>
            <Layout.Item columns="span 6" columnsMd="span 3">
              <LoginOption>
                <LoginOptionButton
                  aria-label="Este id"
                  bankId={1}
                  isForeign={true}
                  handleClick={handleClick}
                >
                  <img src={EsteIdLogo} alt="Este id" />
                </LoginOptionButton>
              </LoginOption>
            </Layout.Item>
            <Layout.Item columns="span 6" columnsMd="span 3">
              <LoginOption>
                <LoginOptionButton
                  aria-label="Swedish bank id"
                  bankId={3}
                  isForeign={true}
                  handleClick={handleClick}
                >
                  <img src={SwedishBankIdlogo} alt="Swedish bank id" />
                </LoginOptionButton>
              </LoginOption>
            </Layout.Item>
            <Layout.Item columns="span 6" columnsMd="span 3">
              <LoginOption>
                <LoginOptionButton
                  aria-label="Norwegian bank id"
                  bankId={4}
                  isForeign={true}
                  handleClick={handleClick}
                >
                  <img src={NorwegianBankIdLogo} alt="Norwegian bank id" />
                </LoginOptionButton>
              </LoginOption>
            </Layout.Item>
          </Layout.Grid>
        </Section>
      </StyledLayoutItem>
      <Layout.Item as="aside" columns="span 12" columnsLg="span 4">
        <Advertisement />
      </Layout.Item>
    </StyledLayoutContainer>
  );
}

export default Content;

const StyledLayoutContainer = styled(Layout.Grid)`
  min-height: calc(100vh - 80px);
  grid-auto-rows: unset;
`;

const StyledLayoutItem = styled(Layout.Item)`
  padding: 16px 16px;
  @media (min-width: ${breakpoint.lg}) {
    padding: 30px 40px 80px;
  }

  @media (min-width: ${breakpoint.md}) and (max-width: ${breakpoint.lg}) {
    padding: 16px 32px;
  }

  @media (min-width: ${breakpoint.sm}) and (max-width: ${breakpoint.md}) {
    padding: 16px 32px;
  }
`;

const Header = styled.header`
  margin-bottom: 16px;
`;

const Section = styled.section`
  margin: 0 auto auto auto;

  @media (min-width: ${breakpoint.sm}) {
    max-width: 830px;
  }
`;
