import React from 'react';
import Notifications from './Components/Notification';
import Content from './Pages/Main/Content';
import Footer from './Pages/Main/Footer';
import Modal from './Components/Modal';

function App() {
  return (
    <>
      <Notifications />
      <Content />
      <Footer />
      <Modal />
    </>
  );
}

export default App;
