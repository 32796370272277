import { useQuery } from 'react-query';
import { QueryError } from '@visma-netvisor/app-tools';
import { LoginMethod } from '../types/loginMethod';
import { ResponseType } from '../types/ResponseType';
import { StartLoginResponse } from '../types/StartLoginResponse';
import { SETTINGS } from '../../constants';
import { togglePoller } from './useGetLoginRequestStatus';
import { useMaintenance, useCheckMaintenance } from '../app/MaintenanceContext';
import { useEffect } from 'react';

let numberOfCodesGenerated = 0;
let loginMethod = LoginMethod.QrCode;
export let generatingCodes = true;
export const resetCodeCounter = () => (numberOfCodesGenerated = 0);
export const resetGeneratingCodes = () => (generatingCodes = true);
export const setLoginMethod = (value: LoginMethod) => {
  loginMethod = value;
};

export const useStartLoginRequest = () => {
  useEffect(() => {
    if (numberOfCodesGenerated < SETTINGS.CODES_TO_GENERATE) return;

    const stopGenerating = setInterval(() => {
      generatingCodes = false;
      togglePoller();
    }, SETTINGS.CODE_VALID_TIME_MS);

    return () => clearInterval(stopGenerating);
  }, [numberOfCodesGenerated]);

  const checkMaintenance = useCheckMaintenance();
  const { maintenance } = useMaintenance();

  return useQuery<StartLoginResponse, QueryError>(
    ['start_login_request'],
    async () => {
      const res = await fetch(
        `${SETTINGS.API_URL}/StartLoginRequest?loginMethod=${loginMethod}&responseType=${ResponseType.Json}`,
        { credentials: 'include' }
      );
      checkMaintenance(res);
      return await res.json();
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: SETTINGS.CODE_VALID_TIME_MS,
      enabled: numberOfCodesGenerated < SETTINGS.CODES_TO_GENERATE,
      retry: maintenance.isMaintenance ? false : 5,
      onSuccess: () => {
        numberOfCodesGenerated++;
      },
    }
  );
};
