import { useEffect, useState } from 'react';
import { useStartLoginRequest } from '../queries/useStartLoginRequest';
import {
  useGetLoginRequestStatus,
  togglePoller,
} from '../queries/useGetLoginRequestStatus';
import { useNote } from './NoteContext';
import { useTranslation } from 'react-i18next';

const checkURLParameter = (label: string) => {
  const url = new URL(location.href);
  return url.searchParams.get(label);
};

const useLogin = () => {
  const { addError, clearMessage } = useNote();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  // Check for login errors, URL only changes on refresh
  // So we only check this once, and on language change.
  useEffect(() => {
    const loginError = checkURLParameter('msg');
    if (loginError) {
      clearMessage(`error_message_${loginError}`, true);
      addError(t(`Errors.${loginError}`), `error_message_${loginError}`);
    }
  }, [lang, t, clearMessage, addError]);

  const { data: loginData, error: loginError } = useStartLoginRequest();
  useEffect(() => {
    if (loginError) {
      addError('Login server error', 'Login Fetch Error');
    }
  }, [loginError, addError]);

  const loginIdentifier = loginData?.Root?.LoginRequest?.LoginIdentifier;
  const { data, isSuccess } = useGetLoginRequestStatus(loginIdentifier);

  const [loginSuccess, setLoginSuccess] = useState(false);

  useEffect(() => {
    if (loginSuccess || !isSuccess || !data?.Root?.Replies?.Reply) {
      return;
    }

    const { LoginRequestStatus, LoginLink: loginLink } =
      data.Root.Replies.Reply;
    if (LoginRequestStatus === 'Accepted') {
      const hostname = new URL(loginLink).hostname;

      const doesLinkLeadToNetvisor =
        hostname.endsWith('netvisor.fi') || hostname.endsWith('nvdev.fi');
      if (!doesLinkLeadToNetvisor) {
        addError(
          `Suspicious login link not leading to Netvisor: ${loginLink}`,
          'Bad Login Link'
        );
        return;
      }

      togglePoller();
      setLoginSuccess(true);
      window.location.replace(loginLink);
    }
  }, [loginSuccess, isSuccess, data, addError]);
};

export default useLogin;
