import { useQuery } from 'react-query';
import { QueryError } from '@visma-netvisor/app-tools';
import { GetLoginStatusResponse } from '../types/GetLoginStatusResponse';
import { ResponseType } from '../types/ResponseType';
import { SETTINGS } from '../../constants';
import { useMaintenance, useCheckMaintenance } from '../app/MaintenanceContext';

let enabled = true;

export const togglePoller = () => {
  enabled = !enabled;
};

export const useGetLoginRequestStatus = (
  loginIdentifier: string | undefined
) => {
  const checkMaintenance = useCheckMaintenance();
  const { maintenance } = useMaintenance();
  return useQuery<GetLoginStatusResponse, QueryError>(
    ['get_login_request_status', loginIdentifier],
    async () => {
      const res = await fetch(
        `${SETTINGS.API_URL}/GetLoginRequestStatus?responseType=${ResponseType.Json}`,
        { credentials: 'include' }
      );
      checkMaintenance(res);
      return await res.json();
    },
    {
      refetchInterval: SETTINGS.GET_LOGIN_REQUEST_STATUS_POLLER_INTERVAL,
      enabled: enabled && Boolean(loginIdentifier),
      retry: maintenance.isMaintenance ? false : 5,
    }
  );
};
