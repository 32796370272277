import { useQuery } from 'react-query';
import { QueryError } from '@visma-netvisor/app-tools';
import { GetCommunityMessagesResponse } from '../types/GetCommunityMessagesResponse';
import { SETTINGS } from '../../constants';
import { useMaintenance, useCheckMaintenance } from '../app/MaintenanceContext';

const useGetCommunityMessages = () => {
  const checkMaintenance = useCheckMaintenance();
  const { maintenance } = useMaintenance();
  return useQuery<GetCommunityMessagesResponse[], QueryError>(
    ['get_community_messages'],
    async () => {
      const res = await fetch(`${SETTINGS.API_URL}/GetCommunityMessages`);
      checkMaintenance(res);
      return await res.json();
    },
    {
      refetchOnWindowFocus: false,
      retry: maintenance.isMaintenance ? false : 5,
    }
  );
};

export default useGetCommunityMessages;
