import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { GetCommunityMessagesResponse } from '../types/GetCommunityMessagesResponse';

const getClosedAlerts = (): string[] => {
  return JSON.parse(localStorage.getItem('closedAlerts') || '[]');
};

type NoteContextType = {
  addMessages: (data: GetCommunityMessagesResponse[]) => void;
  addError: (error: string, id: string) => void;
  clearMessage: (id: string, isError: boolean) => void;
  messages: GetCommunityMessagesResponse[];
};

const NoteContext = createContext<NoteContextType>(null!);

export const NoteProvider = ({ children }: PropsWithChildren) => {
  const [messages, setMessages] = useState<GetCommunityMessagesResponse[]>([]);

  const addMessages = useCallback((data: GetCommunityMessagesResponse[]) => {
    const closedAlerts = getClosedAlerts();
    const filtered = data.filter(
      (v) => !closedAlerts.includes(v.id.toString())
    );
    setMessages((messages) =>
      messages.concat(
        filtered.filter((v) => !messages.find((f) => f.id === v.id))
      )
    );
  }, []);

  const clearMessage = useCallback((id: string, isError: boolean) => {
    const closedAlerts = getClosedAlerts();
    if (!isError && !closedAlerts.includes(id.toString())) {
      closedAlerts.push(id);
      localStorage.setItem('closedAlerts', JSON.stringify(closedAlerts));
    }
    setMessages((messages) => messages.filter((v) => v.id.toString() !== id));
  }, []);

  const addError = useCallback(
    (error: string, id = 'Error') => {
      addMessages([
        {
          id,
          title: error,
          type: 'error',
          updated: 'now',
        },
      ]);
    },
    [addMessages]
  );

  const value: NoteContextType = {
    addMessages,
    addError,
    clearMessage,
    messages,
  };

  return <NoteContext.Provider value={value}>{children}</NoteContext.Provider>;
};

export const useNote = () => {
  return useContext(NoteContext);
};
