import {
  Button,
  Layout,
  Typography,
} from '@visma-netvisor/react-component-library';
import { ArrowUpRightIcon } from '@visma-netvisor/nv-react-icons';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useModal } from '../../../api/app/ModalContext';
import vismaLogo from '../../../assets/images/visma-logo.svg';
import languageFinnish from '../../../assets/images/languagefinnish20x20.svg';
import languageSwedish from '../../../assets/images/languageswedish20x20.svg';
import languageEnglish from '../../../assets/images/languageukenglish20x20.svg';

const Languages = {
  fi: {
    icon: languageFinnish,
    text: 'Suomi',
    html: 'fi',
  },
  en: {
    icon: languageEnglish,
    text: 'English',
    html: 'en',
  },
  sv: {
    icon: languageSwedish,
    text: 'Svenska',
    html: 'sv',
  },
};

function Footer() {
  const { t, i18n } = useTranslation();
  const { setModal } = useModal();
  const [showLanguageMenu, setShowLanguageMenu] = React.useState(false);

  // Sometimes when the production build is loaded, the links in the footer won't
  // be rendered correctly. They can be hovered and clicked on, but no visuals
  // are displayed.
  //
  // Enabling 6x CPU Throttling from Devtools -> Performance Insights, this can be
  // reproduced in development. No root cause was found, but it's somehow related
  // to having too many components. Removing some components from the Content
  // component will bring the links back up. But with no relevant indication
  // as to why.
  //
  // This is a workaround for that, please forgive me.

  const [render, setRender] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setRender(true);
    }, 0);
  }, []);

  if (!render) {
    return null;
  }

  const setLanguage = (lang: string) => {
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang);
      document.documentElement.lang = lang;

      localStorage.setItem('lang', lang);

      const url = new URL(location.href);
      url.pathname = `/${lang}`;
      window.history.replaceState(null, '', url);
    }
    setShowLanguageMenu(false);
  };

  return (
    <Container>
      <LinkSection>
        <Layout.Flex flexDirection="row" alignItems="center">
          <ExtendedUl>
            <ExtendedLi>
              <ExtendedButton
                variant="link"
                onClick={() => setModal('LoginProblems')}
              >
                {t('LinksFooter.LoginProblem')}
              </ExtendedButton>
            </ExtendedLi>
            <ExtendedLi>
              <BlackLink href={t('Urls.Announcements')} target="_blank">
                {t('LinksFooter.Announcements')}
              </BlackLink>
              <ArrowUpRightIcon fillColor="primary" fontSize={14} />
            </ExtendedLi>
            <ExtendedLi>
              <BlackLink href={t('Urls.Community')} target="_blank">
                {t('LinksFooter.Community')}
              </BlackLink>
              <ArrowUpRightIcon fillColor="primary" fontSize={14} />
            </ExtendedLi>
            <ExtendedLi>
              <BlackLink href={t('Urls.Support')} target="_blank">
                {t('LinksFooter.Support')}
              </BlackLink>
              <ArrowUpRightIcon fillColor="primary" fontSize={14} />
            </ExtendedLi>
            <ExtendedLi>
              <ExtendedButton
                variant="link"
                onClick={() => setShowLanguageMenu(!showLanguageMenu)}
              >
                <LanguageSelectionTextContainer>
                  <LanguageSelectionIcon src={Languages[i18n.language].icon} />
                  <LanguageSelectionText>
                    {Languages[i18n.language].text}
                  </LanguageSelectionText>
                </LanguageSelectionTextContainer>
              </ExtendedButton>
              {showLanguageMenu ? (
                <LanguageSelectionMenuContainer>
                  {Object.values(Languages).map((language) => (
                    <LanguageSelectionMenuButton
                      onClick={() => setLanguage(language.html)}
                      key={language.html}
                    >
                      <LanguageSelectionMenuEntry>
                        <LanguageSelectionIcon src={language.icon} />
                        <LanguageSelectionMenuEntryText>
                          {language.text}
                        </LanguageSelectionMenuEntryText>
                      </LanguageSelectionMenuEntry>
                    </LanguageSelectionMenuButton>
                  ))}
                </LanguageSelectionMenuContainer>
              ) : null}
            </ExtendedLi>
            <ExtendedLi>
              <Typography as="span" color="primary">
                <Trans
                  i18nKey="LinksFooter.Sales"
                  components={[
                    <BlackLink href="tel:0107783700"></BlackLink>,
                    <BlackLink href="mailto:myynti.netvisor@visma.com"></BlackLink>,
                  ]}
                />
              </Typography>
            </ExtendedLi>
          </ExtendedUl>
        </Layout.Flex>
      </LinkSection>
      <VismaSection>
        <Layout.Flex flexDirection="row" alignItems="center">
          <ExtendedUl>
            <ExtendedLi>
              <Typography as="span" color="whiteInverse">
                <Trans
                  i18nKey="VismaFooter.VismaGroup"
                  components={[
                    <WhiteLink
                      href={t('Urls.Netvisor')}
                      target="_blank"
                    ></WhiteLink>,
                    <WhiteLink
                      href={t('Urls.VismaGroup')}
                      target="_blank"
                    ></WhiteLink>,
                  ]}
                />
              </Typography>
            </ExtendedLi>

            <ExtendedLi>
              <Typography as="span" color="whiteInverse">
                <WhiteLink href={t('Urls.PrivacyPolicy')} target="_blank">
                  {t('VismaFooter.VismaPrivacy')}
                </WhiteLink>
              </Typography>
            </ExtendedLi>

            <ExtendedLi>
              <Typography as="span" color="whiteInverse">
                <WhiteLink href={t('Urls.DataPrivacy')} target="_blank">
                  {t('VismaFooter.VismaSecurity')}
                </WhiteLink>
              </Typography>
            </ExtendedLi>
          </ExtendedUl>
        </Layout.Flex>
        <FooterLogo src={vismaLogo} />
      </VismaSection>
    </Container>
  );
}

export default Footer;

const Container = styled.footer`
  width: 100%;
  position: sticky;
  padding: 0px;

  @media (min-width: 1199px) {
    bottom: 0;
  }

  @media (max-width: 1199px) {
    min-width: 25rem;
    display: flex;
  }

  @media (max-width: 600px) {
    display: block;
  }
`;

const LinkSection = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #eaf1f8;
  justify-content: space-between;
  align-items: center;
  color: #333333;
  letter-spacing: 0.03rem;
  position: relative;

  @media (max-width: 1199px) {
    max-width: 50%;
    height: 221px;
    display: block;
    padding-top: 9px;

    > footer > ul > li:not(:first-child) {
      padding-bottom: 10px;
    }
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const VismaSection = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: #003254;
  justify-content: space-between;
  align-items: center;
  color: #e6e6e6;
  letter-spacing: 0.03rem;

  @media (max-width: 1199px) {
    max-width: 50%;
    height: 215px;
    display: block;
    padding-top: 15px;

    > footer > ul > li {
      padding-bottom: 10px;
    }
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const ExtendedUl = styled.ul`
  list-style-type: none;
  padding-left: 23px;
  margin: 0px;

  @media (max-width: 1199px) {
    position: unset;
  }

  @media (max-width: 600px) {
    position: unset;
  }
`;

const ExtendedLi = styled.li`
  @media (min-width: 1199px) {
    display: table-cell;

    :after {
      content: '|';
      padding-left: 8px;
      padding-right: 8px;
      color: #9eb7d7;
    }

    :last-child:after {
      content: none;
    }
  }
  @media (min-width: 1275px) {
    :after {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
`;

const FooterLogo = styled.img`
  box-sizing: border-box;
  display: block;
  width: auto;
  height: 20px;
  margin: 1em;
  align-items: center;
  padding-right: 8px;

  @media (max-width: 1199px) {
    margin: 10px 0 0 23px;
  }
`;

const LanguageSelectionTextContainer = styled.div`
  box-sizing: border-box;
  display: block;
  align-items: center;
  padding: 0 0 0 2.25em;
  min-width: 5em;
  position: relative;
`;

const LanguageSelectionIcon = styled.img`
  box-sizing: border-box;
  box-shadow: 0 1px 2px 2px rgb(0 0 0 / 10%);
  border-radius: 1000px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 1.5em;
  width: 1.5em;
  margin-right: 10px;
`;

const LanguageSelectionText = styled.div`
  box-sizing: border-box;
  display: block;
  flex: 1 1 auto;
  white-space: nowrap;
`;

const LanguageSelectionMenuContainer = styled.div`
  padding: 0;
  margin-bottom: 1em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgb(0 0 0 / 15%);
  width: 13rem;
  position: absolute;
  bottom: 100%;
  font-size: 1.2rem;

  :after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top-color: white;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-top-width: 8px;
    border-bottom-width: 0px;
    border-left-width: 8px;
    border-right-width: 8px;
    bottom: -8px;
    left: 0.5rem;
  }

  @media (max-width: 1199px) {
    bottom: 40%;
  }

  @media (max-width: 600px) {
    bottom: 40%;
  }
`;

const LanguageSelectionMenuButton = styled.button`
  display: block;
  width: 100%;
  padding: 0.5em;
  border: 0;
  background: #ffffff;
  text-align: left;

  :hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
`;

const LanguageSelectionMenuEntry = styled.div`
  box-sizing: border-box;
  display: block;
  align-items: center;
  padding: 0 0 0 2.25em;
  min-width: 5em;
  position: relative;
`;

const LanguageSelectionMenuEntryText = styled.span`
  box-sizing: border-box;
  display: block;
  flex: 1 1 auto;
  white-space: nowrap;
`;

const WhiteLink = styled.a`
  text-decoration: none;
  white-space: nowrap;
  color: #e6e6e6;

  :hover {
    text-decoration: underline;
  }
`;

const BlackLink = styled.a`
  text-decoration: none;
  white-space: nowrap;
  color: #003254;
  font-size: 14px;

  :hover {
    text-decoration: underline;
  }
`;

const ExtendedButton = styled(Button)`
  color: #003254 !important;
  gap: 0px;
  letter-spacing: 0.05em;
  padding: 0px;

  svg {
    filter: grayscale(100%);
  }
`;
