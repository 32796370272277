import React from 'react';
import {
  Layout,
  Link,
  Typography,
} from '@visma-netvisor/react-component-library';

import {
  WarningFilledIcon,
  CrossIcon,
  InfoCircleFilledIcon,
} from '@visma-netvisor/nv-react-icons';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

export interface NotificationItemProps {
  id: string;
  title: string;
  variant: 'warning' | 'info' | 'error';
  clearMessage: (id: string, isError: boolean) => void;
}

const NotificationItem = ({
  id,
  title,
  variant,
  clearMessage,
}: NotificationItemProps) => {
  const { t } = useTranslation();
  return (
    <Container
      id={id}
      variant={variant}
      flexDirection="row"
      flexGap={28}
      justifyContent="flex-start"
      alignItems="center"
      role={variant === 'error' ? 'alert' : 'notification'}
    >
      <IconWrapper>
        {variant === 'warning' && (
          <WarningFilledIcon fillColor="whiteInverse" />
        )}
        {variant === 'info' && (
          <InfoCircleFilledIcon fillColor="whiteInverse" />
        )}
      </IconWrapper>
      <div>
        <Typography color="whiteInverse" variant="headingH5Regular">
          {title}
        </Typography>{' '}
        {variant !== 'error' && (
          <StyledLink
            withIcon
            href={
              'https://support.netvisor.fi/fi/support/discussions/topics/' + id
            }
            target="_blank"
          >
            {t('ReadMore')}
          </StyledLink>
        )}
      </div>
      <ButtonWrapper>
        <CloseButton
          title={t('Close')}
          onClick={() => clearMessage(id, variant === 'error')}
        >
          <CrossIcon fillColor="whiteInverse" />
        </CloseButton>
      </ButtonWrapper>
    </Container>
  );
};

export default NotificationItem;

const Container = styled(Layout.Flex)<{
  variant: NotificationItemProps['variant'];
}>`
  padding: 0.5rem 1.75rem;
  font: 400 12px/12px 'Open Sans', Arial, sans-serif;
  color: #ffffff;

  & + & {
    border-width: 1px 0 0 0;
    border-style: solid;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'warning':
        return css`
          background-color: #f29100;
          border-color: #d98200;
        `;
      case 'error':
        return css`
          background-color: #d93644;
          border-color: #d98200;
        `;
      case 'info':
      default:
        return css`
          background-color: #333333;
          border-color: #404040;
        `;
    }
  }}
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
`;

const ButtonWrapper = styled.div`
  flex-shrink: 0;
  margin-left: auto;
`;

const StyledLink = styled(Link)`
  display: inline-block;
  margin-left: 0.2em;
  color: inherit;
  font: inherit;
  font-weight: bold;

  &:hover,
  &:visited {
    color: inherit;
  }

  > svg > path {
    fill: #ffffff;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  margin-left: auto;
  font: inherit;
`;
