import { Step, StepButton, StepButtonWrapper } from './Step';
import { StepLabel } from './StepLabel';
import styled from 'styled-components';

const Stepper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  display: inline-flex;
  align-items: flex-end;
  flex-direction: column;
`;

export { Stepper, Step, StepButton, StepButtonWrapper, StepLabel };
